import React from 'react';

function Price() {
    const list = [
        {
            title: 'Editing & Proofreading',
            text: 'Starting at <span class="text-primary font-[600]">$22</span> per 250 words'
        },
        {
            title: 'Business plans & marketing plans',
            text: 'Starting at <span class="text-primary font-[600]">$275</span>'
        },
        {
            title: 'Blog Articles & News',
            text: 'Starting at <span class="text-primary font-[600]">$175</span>'
        },
        {
            title: 'Website Content',
            text: 'Starting at <span class="text-primary font-[600]">$25</span> per 280 words'
        },
        {
            title: 'SMM Posts That Sell',
            text: 'Starting at <span class="text-primary font-[600]">$46</span> for FB/Insta post'
        },
        {
            title: 'Rewriting Legacy Content',
            text: 'Starting at <span class="text-primary font-[600]">$26</span> per 250 words'
        },
        {
            title: 'Email Campaigns - Selling Emails for Retention',
            text: 'Starting at <span class="text-primary font-[600]">$65</span> for an e-mail'
        },
        {
            title: 'Informational Content – Flyers or About the Brand',
            text: 'Starting at <span class="text-primary font-[600]">$128</span> for brand content'
        },
    ]
    return (
        <div className='flex justify-center md:mt-0 mt-20'>
            <div className='container'>
                <h2 className='text-primary md:text-6xl text-4xl mb-10 font-[600]'>Prices</h2>
                <div className='flex flex-wrap'>
                    {list.map((item, index) => {
                        return(
                            <div key={index} className="md:w-[350px] w-full p-7 bg-[#f9f9fc] rounded-br-[50px] mr-5 mb-10">
                                <h3 className='text-2xl mb-3 font-[600]'>{item.title}</h3>
                                <div dangerouslySetInnerHTML={{__html: item.text}} className="mb-3"/>
                                <span className='block w-10 h-px bg-slate-500'/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Price;