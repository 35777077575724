import React from 'react';

function Services(props) {
    const list = [
        {
            title: 'Writing / rewriting / updating legacy & AI-generated content',
            text: `Information changes over time and outdated content can easily fall through the cracks of search engines. Want to keep your original content but also improve it at the same time? Our team can accomplish that. We can enhance your existing content by employing best practices in search engine optimization.`
        },
        {
            title: 'Editing & proofreading',
            text: `Whether your document requires a rapid check for spelling and grammar or a thorough developmental edit, we provide flexible editing options. Tailor your editing experience with our experts to meet your specific needs.`
        },
        {
            title: 'Powerpoint presentations',
            text: 'Our experts will craft a very high quality presentation for you based on your incoming set of requirements'
        },
        {
            title: 'Business plans & marketing plans',
            text: 'Our experts can prepare solid and professionally written business plans and marketing plans backed up by quality research'
        },
        {
            title: 'Fiction writing packages',
            text: `Have a fantastic story idea? Partner with our experienced team of fiction writers to transform it into an unforgettable page-turner.`
        },
        {
            title: 'Articles',
            text: 'We produce accurate, engaging, and well-researched articles for blogs and newspapers. Our talent for writing short-form content guarantees that information is delivered quickly and efficiently.'
        },
        {
            title: 'Technical documentation',
            text: `Our excellent copywriters can create technical documentation, manuals, guides for appliances, equipment, electronics, etc.`
        },
        {
            title: 'Website text',
            text: `The text we provide for your website’s pages like Home Page, FAQs, and About is exquisitely written to ensure keyword optimization and high conversion.
            We write strategically crafted copy to highlight your product or service and make your website sell.`
        },
        {
            title: 'Book bundles',
            text: `Prepare to launch your next hit with our comprehensive book bundles. Encompassing services from cover design to the final page and everything in between.`
        },
        {
            title: 'Social media content',
            text: `If you need to boost your social media presence, we are experienced in providing clients the text needed to gain content views. We specialize in formulating marketable text, whether it's naming, slogans, or just good copy, for banner ads or posts that will appear on Facebook, Instagram, Twitter, and other popular social media websites.`
        },
        {
            title: 'Email campaigns',
            text: 'We can help you keep your clientele informed, interested, and engaged. Our expertise in leveraging the power of well-designed email campaigns guarantees that you will retain and grow your mailing list.'
        },
        {
            title: 'Print ads and digital infographics',
            text: 'Our team crafts informational content that embodies the tone, design, and aesthetic your business requires. Our products pack a lot of style and substance whether in print or digital format.'
        },
        {
            title: 'Illustrations & cover design',
            text: 'Our team of versatile illustrators specializes in producing tailored artwork for various purposes.'
        }
        
    ]
    return (
        <div className='flex justify-center pt-20'>
            <div className='container'>
                <h2 className='text-primary md:text-5xl text-3xl md:mb-10 mb-5 font-[600]'>
                    We believe in &laquo;HUMAN WRITING&raquo;
                </h2>
                <p className='w-full xl:w-3/4 md:text-xl text-lg md:mb-10 mb-5 font-[400]'>
                    As awesome as it is, <span className='bg-yellow-200 p-1 font-bold'>ChatGPT creates content that is easily detected as &laquo;AI-generated&raquo; by Turnitin.com</span>. 
                    If you are serious about your project, you should not trust ChatGPT blindly!
                    Either rewrite it yourself or hire somebody to do that for you! 
                </p>
                <p className='w-full xl:w-3/4 md:text-xl text-lg md:mb-10 mb-5 font-[400]'>
                    That's where our service really shines!
                    Our content experts do not use AI! They are old-fashioned nerds who love to use a spell-checker as their only tool! 
                    They will rewrite any AI-generated text or will write from scratch as needed!
                </p>
                <h2 className='text-primary md:text-5xl text-3xl md:mb-10 mb-5 font-[600] mt-20'>
                    Our services are affordable! <br/> <span className='bg-yellow-200 font-bold'>From 0.1&#162; per word.</span>
                </h2>
                <div className='md:w-5/6 w-full mx-auto'>
                    {list.map((item, index) => {
                        return(
                            <div
                                key={index}
                                className='bg-slate-100 rounded-2xl mb-10 lg:p-10 md:p-8 p-6 flex md:flex-row flex-col justify-between items-start border-b border-b-grey last:border-none'
                            >
                                <div className='w-full md:w-5/6 lg:text-2xl md:text-xl text-xl font-[700] lg:mr-10 mr-5 mb-2'>{item.title}</div>
                                <div className='w-full md:leading-relaxed font-thin'>
                                    <div className='w-full md:text-xl text-lg md:leading-relaxed text-[rgba(47,47,47,0.6)] font-thin'>{item.text}</div>
                                    <div className='flex justify-end text-right'>
                                        <a href="https://shop.kontentify.io/order/express" className='text-white bg-blue-600 px-5 py-3 mt-5 rounded-xl font-bold'>Check price</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Services;