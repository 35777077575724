import React from 'react';

//images
import Writing from '../images/writing.jpg'
import Office from '../images/office.jpg'
import Team from '../images/team.jpg'
import TeamHands from '../images/team-hands.jpeg'
import Old from '../images/old.jpg'

function HowItWorks() {
    const list = [
        {
            title: 'Register on our platform',
            text: 'Our user-friendly system promises a quick, easy, and absolutely stress-free sign-up process.',
            img: Writing
        },
        {
            title: 'Send your assignment requirements',
            text: `Our experts are as creative as they are capable of executing our client’s specific instructions.`,
            img: Office
        },
        {
            title: 'Choose your professional',
            text: 'Read our experts’ profiles and reviews. Then pick the professional that best fits your task’s requirements. It is not self-service! We do have great customer service every step of the way!',
            img: Team
        },
        {
            title: 'Track the task’s progress',
            text: 'We provide real-time updates on your task’s progress. Stay informed by simply logging on to our website.',
            img: TeamHands
        },
        {
            title: 'Receive the finished document',
            text: `Our team will submit the completed work on or before your deadline. Let us know of any adjustments that you might require as they are done free of charge.`,
            img: Old
        }
    ]
    return (
        <div className='flex justify-center pt-20 font-thin'>
            <div className='container flex flex-col'>
                <h2 className='text-primary md:text-6xl text-4xl md:mb-10 mb-5 font-[600]'>Our perfect process.</h2>
                <p className='max-w-p[570px] text-xl text-[rgba(47,47,47,.6)] md:mb-20 mb-10'>
                    We are a professional writing agency.
                    We have a team of highly professional and trained English language experts who love to write and edit for a living.
                </p>
                {list.map((item, index) => {
                    return(
                        <div key={index} className='hww-wrapper flex md:flex-row flex-col items-start md:even:flex-row-reverse md:mb-12 mb-5 pb-12'>
                            <div className='hww-info flex flex-col md:mb-0 mb-10'>
                                <div className='mb-2 text-xl'>Step: {index + 1}</div>
                                <div className='md:max-w-[380px] max-w-full text-3xl mb-5 font-[700]'>{item.title}</div>
                                <div className='md:w-2/3 w-full text-xl md:leading-relaxed text-[rgba(47,47,47,.6)] font-thin'>{item.text}</div>
                            </div>
                            <div className='hww-img relative md:min-w-[470px]'>
                                <img className='image w-full rounded-br-[80px] relative z-20' src={item.img} alt={item.title} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default HowItWorks;