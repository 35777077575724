import React from 'react';
import OfficeWoman from '../images/office-woman.jpeg'
import Check from '../images/check.svg'
function WhyWe() {
    const list = [
        {
            title: 'Professional and Talented Team'
        },
        {
            title: 'Fast Delivery'
        },
        {
            title: 'Top-Quality Work'
        },
        {
            title: 'Affordable Rates'
        },
        {
            title: 'Unlimited Revisions'
        },
        {
            title: '100% Original Work'
        },
        {
            title: 'User-Friendly System'
        },
        {
            title: 'Satisfaction Guarantee'
        },
    ]
    return (
        <div className='container md:py-20 py-0 my-0 mx-auto'>
            <h2 className='text-primary md:text-6xl text-4xl mb-5 font-[600]'>Why we stand out</h2>
            <p className='md:max-w-p[570px] text-xl text-[rgba(47,47,47,.6)] mb-10'>
                Here are a few compelling reasons why you should trust us with your important project.
            </p>
            <div className='why-we flex md:flex-row flex-col-reverse items-center'>
                <div className='hww-img relative md:w-full md:max-w-[470px]'>
                    <img className='image w-full rounded-br-[80px] relative z-20' src={OfficeWoman} alt="OfficeWoman" />
                </div>
                <div className='md:w-1/2 md:ml-20 md:mb-0 mb-10'>
                    <div className='flex flex-wrap md:max-w-[500px]'>
                        {list.map((item, index)=> {
                            return(
                                <p key={index} className="why-we-title flex items-center w-1/2 mb-5 text-lg">
                                    <img src={Check} alt="check" className='mr-2'/>
                                    {item.title}
                                </p>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyWe;