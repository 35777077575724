import React from 'react';
import Slider from "react-slick";
//data
import reviewsData from '../content/reviews.json'
//images
import Quote from '../images/quote.svg'
function Reviews() {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    const starsCounter = (rate) => {
        let count = ''
        for(let i = 0; i < rate; i++) {
            count +=`<img class="mr-1" src="${require('../images/star.svg').default}">`
        }
        return count
    }
    return (
        <div className='flex justify-center'>
            <div className='container'>
                <h2 className='text-primary text-6xl mb-10 font-[600]'>Reviews</h2>
                <Slider {...settings}>
                    {reviewsData.map((item, index) => {
                        return(
                            <div key={index} className="slide-wrapper">
                                {/* <div className='md:block hidden'>
                                    <img src={Quote} alt="Quote" className='w-24 h-10'/>
                                </div> */}
                                <div className='md:ml-5'>
                                    <div className='text-3xl mb-5 font-[500]'>&laquo;{item.title}&raquo;</div>
                                    <div className='flex items-center'>
                                        <div dangerouslySetInnerHTML={{__html: starsCounter(item.rate)}} className="flex mr-2"/>
                                        <span className='block w-px h-5 bg-slate-300 mr-3'/>
                                        <span className='text-xl'>{item.rate}.0</span>
                                    </div>
                                    <div className='pt-10 text-xl md:leading-relaxed text-[rgba(47,47,47,0.6)]'>{item.review}</div>
                                    <div>
                                        <div className='text-xl text-primary mt-5'>{item.author}</div>
                                        <div className='mt-2'>{item.date}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </div>
    );
}

export default Reviews;