import React from 'react';
// import axios from 'axios'
// import { useForm } from "react-hook-form";
function ContactUs() {
    // const { register, handleSubmit, reset, formState: { errors } } = useForm();
    // const onSubmit = async (data) => {
    //     try {
    //         await axios.post('https://shop.customessaymeister.com/api/support/guest/create-ticket-short', {
    //             data_support: {message: data.message},
    //             data_client: {email: data.email}
    //         })
    //     }catch(e) {
    //         console.log(e);
    //     }finally {
    //         reset()
    //     }
    // };

    return (
        <div className='flex justify-center pt-10 pb-20'>
            <div className='container'>
                <h2 className='text-slate-500 md:text-6xl text-4xl mb-10 font-[600]'>Got questions?</h2>
                <div className='flex'>
                    {/* <form onSubmit={handleSubmit(onSubmit)}>*/}
                    {/*    <div>*/}
                    {/*        <input*/}
                    {/*            className={`md:w-1/3 w-full pb-2 focus:outline-none focus:shadow-outline border-b border-b-slate-300 ${errors?.email ? 'border-b-red-500' : ''}`}*/}
                    {/*            id="email"*/}
                    {/*            type="text"*/}
                    {/*            placeholder="Email"*/}
                    {/*            {...register('email', {*/}
                    {/*                required: 'Required',*/}
                    {/*                pattern: {*/}
                    {/*                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,*/}
                    {/*                    message: 'Must be a valid e-mail'*/}
                    {/*                }*/}
                    {/*            })}*/}
                    {/*        />*/}
                    {/*        <div className='text-red-700 mt-1 mb-10'>*/}
                    {/*            {errors?.email && <p>{errors.email.message}</p>}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div>*/}
                    {/*        <input*/}
                    {/*            type="text"*/}
                    {/*            className={`md:w-1/3 w-full pb-2 focus:outline-none focus:shadow-outline border-b border-b-slate-300 ${errors?.email ? 'border-b-red-500' : ''}`}*/}
                    {/*            placeholder="Details of your assignment"*/}
                    {/*            {...register('message', {*/}
                    {/*                required: 'Required',*/}
                    {/*                minLength: {*/}
                    {/*                    value: 20,*/}
                    {/*                    message: 'Must be at least 20 characters.'*/}
                    {/*                },*/}
                    {/*                maxLength: {*/}
                    {/*                    value: 9000,*/}
                    {/*                    message: 'Must be less than 9000 characters.'*/}
                    {/*                }*/}
                    {/*            })}*/}
                    {/*        />*/}
                    {/*        <div className='text-red-700 mt-1 mb-5'>*/}
                    {/*            {errors?.message && <p>{errors.message.message}</p>}*/}
                    {/*        </div>*/}
                    {/*        <button*/}
                    {/*            type="submit"*/}
                    {/*            className="flex justify-center items-center rounded py-2 px-8 mt-10 border text-white border-primary bg-primary"*/}
                    {/*        >*/}
                    {/*            Submit*/}
                    {/*        </button>*/}
                    {/*    </div>*/}
                    {/*</form> */}
                    <a href="https://shop.kontentify.io/support/ticket/new" className='font-bold flex justify-center items-center rounded py-3 px-10 border text-white border-primary bg-slate-500'>
                        Contact Us
                    </a>
                </div>
            </div>

            <div className='container'>
                <h2 className='text-primary md:text-6xl text-4xl mb-10 font-[600]'>or get started</h2>
                
                <div className='flex'>
                    <a href="https://shop.kontentify.io/register" className='font-bold flex justify-center items-center rounded py-3 px-10 border text-white border-primary bg-primary'>
                        Sign Up
                    </a>
                </div>
            </div>
        </div>
    );
}

export default ContactUs;