import React from "react";
function Hero(props) {
	return (
        <section className="flex justify-center bg-[url('../images/hero-bg-1.jpg')] bg-cover md:min-h-[570px] md:pb-0 pb-20 pt-[122px] text-white">
            <div className="container">
                <h1 className="md:text-6xl text-4xl mt-12 md:max-w-[720px] font-[700]">
                    <span className="bg-clip-text text-transparent bg-gradient-to-r from-slate-400 to-slate-200">AI content is detectable and can get you in trouble!</span>
                </h1>
                <p className="text-xl leading-relaxed mt-5 max-w-[620px]">
                Submitting content written by AI such as ChatGPT 4 will get you in trouble because it will be flagged by checkers like Turnitin. <b>The solution is to hire a human expert on our platform to fix your content or write from scratch!</b>
                </p>
                <div className="flex flex-col md:flex-row mt-10 items-center mb-5 gap-4 md:gap-8">
                    <div>
                        <a href="https://shop.kontentify.io/order/express" className='lg:ml-10 text-xl flex justify-center items-center w-[300px] v-sm:w-[350px] rounded py-4 px-10 border-transparent bg-white font-bold text-black hover:bg-slate-200 hover:text-stone-600'>
                            Get started
                        </a>
                    </div>
                    <div className="text-green-100 text-xl">
                        or call
                    </div>
                    <div className="text-xl lg:text-3xl font-bold text-red-800 bg-clip-padding p-4 rounded-full bg-white border-8 border-blue-700">
                        <div>+ 1 (313) 264-0133</div>
                    </div>
                </div>
            </div>
        </section>
	);
}

export default Hero;
