import * as React from "react";

import Layout from '../layouts/layout'
//components
import Hero from '../components/Hero'
import Rate from '../components/Rate'
import Services from '../components/Services'
import HowItWorks from '../components/HowItWorks'
import Reviews from '../components/Reviews'
import WhyWe from '../components/WhyWe'
import Price from '../components/Price'
import ContactUs from '../components/ContactUs'

const IndexPage = () => {
	return (
        <div>
            <Layout>
                <Hero />
                {/* <Rate /> */}
                <Services />
                <HowItWorks />
                <Reviews />
                <WhyWe />
                <Price />
                <ContactUs />
            </Layout>
        </div>
    )
};

export default IndexPage;
